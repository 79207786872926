<template>
  <v-dialog
    v-model="dialogLocal"
    fullscreen
    @keydown.esc="loading ? null : (dialogLocal = false)"
  >
    <v-card>
      <v-card-title class="headline">
        Gerenciar Metas dos KPIs
      </v-card-title>
      <v-card-text>
        <v-row class="mx-auto">
          <v-col cols="12" class="ml-2">
            <h3>Baixar Metas Cadastradas</h3>
          </v-col>
          <v-col cols="auto" class="ml-2">
            <v-btn
              color="primary"
              dark
              elevation="1"
              relative
              text
              medium
              @click="baixarMetasKpis('geral')"
              :loading="loading"
            >
              <v-icon left size="30px">
                mdi-microsoft-excel
              </v-icon>
              Metas Gerais
            </v-btn>
          </v-col>
          <v-col cols="auto" class="ml-2">
            <v-btn
              color="primary"
              dark
              elevation="1"
              relative
              text
              medium
              :loading="loading"
              @click="baixarMetasKpis('municipios')"
            >
              <v-icon left size="30px">
                mdi-microsoft-excel
              </v-icon>
              Metas por Município
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mx-auto mt-5">
          <v-col cols="12" class="ml-2">
            <h3>Cadastrar Metas</h3>
          </v-col>
          <v-col cols="6" sm="3" class="ml-2">
            <v-select
              :items="[
                { text: 'Geral', value: 'geral' },
                { text: 'Municípios', value: 'municipios' },
              ]"
              hide-details
              outlined
              v-model="tipoDeCadastro"
              label="Tipo de Cadastro"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <upload-files
              id="fs-upload-importacao-dados"
              :allowedFiles="allowedFiles"
              :allowMultipleUploads="false"
              @file:uploaded="handleFileUploaded"
            >
              <template v-slot:upload-alert-extra-information>
                <slot
                  v-if="tipoDeCadastro === 'geral'"
                  name="cadatro-geral-extra-info"
                />
                <slot
                  v-else-if="tipoDeCadastro === 'municipios'"
                  name="cadatro-municipios-extra-info"
                />
              </template>
            </upload-files>
          </v-col>
        </v-row>
        <v-row class="mx-auto ml-2" v-if="lastUploadedFile">
          <v-col cols="12">
            <v-text-field
              label="Arquivo enviado"
              outlined
              readonly
              v-model="lastUploadedFile.arquivo"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="lastUploadedFile === undefined"
          :loading="loading"
          color="success"
          elevation="1"
          @click="importaMetasKpis()"
        >
          Cadastrar
        </v-btn>
        <v-btn
          :disabled="loading"
          color="primary"
          text
          @click="dialogLocal = false"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    components: {
      SelectEncoding: () => import("@/components/general/SelectEncoding.vue"),
      UploadFiles: () => import("@/components/general/UploadFiles/Index.vue"),
    },
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        encoding: "UTF-8",
        allowedFiles: ["csv"],
        uploadedFiles: [],
        loading: false,
        tipoDeCadastro: "geral",
      };
    },
    computed: {
      dialogLocal: {
        get() {
          return this.dialog;
        },
        set(newValue) {
          this.$emit("update:dialog", newValue);
        },
      },
      lastUploadedFile() {
        return this.uploadedFiles[this.uploadedFiles.length - 1];
      },
    },
    methods: {
      handleFileUploaded(event) {
        const { files } = event;
        files.forEach((file) => this.uploadedFiles.push(file));
      },
      importaMetasKpis() {
        this.$emit("importaMetasKpis");
      },
      baixarMetasKpis(tipoDeCadastro) {
        this.$emit("baixarMetasKpis", tipoDeCadastro);
      },
    },
  };
</script>
